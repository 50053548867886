import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import { Container, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { withLayout } from "../components/Layout";

/** data format of the graphQL query for the optimized image */
type ImgData = {
  childImageSharp: {
    fluid: any;
  };
};

const TeamPage = ({
  data,
}: {
  data: {
    amoolya: ImgData;
    doug: ImgData;
    josh: ImgData;
    jenny: ImgData;
    kevin: ImgData;
    bryan: ImgData;
    ernst: ImgData;
    calvin: ImgData;
    luis: ImgData;
    nick: ImgData;
    nishant: ImgData;
    isaac: ImgData;
    guzman: ImgData;
    team: ImgData;
  };
}) => {
  const {
    amoolya,
    bryan,
    calvin,
    ernst,
    doug,
    josh,
    jenny,
    kevin,
    luis,
    nick,
    guzman,
    nishant,
    isaac,
    team,
  } = data;

  return (
    <Segment className="team-container alternate stripe" padded="very">
      <Container>
        <Header as="h1" textAlign="center">
          Team
        </Header>

        <Divider hidden />
        <Divider hidden />

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={doug.childImageSharp.fluid}
                alt="Doug Densmore"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Doug Densmore, Ph.D.</Header>
              <Header as="h5">Co-founder and President</Header>
              <p>
                Doug is an expert in Synthetic Biology and one of the founders of Bio-Design 
                Automation (BDA) as a discipline. He is currently the Tegan Family Distinguished 
                Faculty Fellow, a Biological Design Center founding member, and Professor in the 
                Department of Electrical and Computer Engineering at Boston University. He is an 
                NSF CAREER Award recipient, AIMBE Fellow, and is an affiliated PI with the 
                Engineering Biology Research Center. Related activities include: President of 
                the Bio-Design Automation Consortium; President of the Nona Research Foundation; 
                Founder and general chair of the International Workshop on Bio-Design Automation (IWBDA); 
                Director of the Design Automation, Manufacturing, and Processes (DAMP) Lab; and former NSF 
                Expeditions in Computing Lead PI (“Living Computing Project”).
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={kevin.childImageSharp.fluid}
                alt="Kevin LeShane"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Kevin LeShane</Header>
              <Header as="h5">Vice President</Header>
              <p>
                Kevin received a Masters degree in Computer Engineering from
                Boston University and has a degree in biology. He was a
                contributor to algorithms, APIs, data visualization, and user
                experience for core software projects (Clotho, Raven, Phoenix)
                in the Cross-disciplinary Integration of Design Automation
                Research lab. He was the recipient of an NSF SBIR Phase I award:
                "Commercialization of Bio-Design Automation Software for
                Synthetic Biology" and most recently an NSF SBIR Phase II award:
                "Fully Integrated Design and Automated Fabrication Services
                Software Platform for Engineering Living Systems". Kevin
                participated in the earliest consulting efforts and was the sole
                software engineer on Lattice’s first customer engagements. He is
                a full-stack developer with a praised design aesthetic who is
                equally comfortable acting as a business liaison, company
                leader, and programmer.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={nick.childImageSharp.fluid}
                alt="Nick Rosenau"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Nick Rosenau</Header>
              <Header as="h5">Software Engineering</Header>
              <p>
              Nick graduated from Emmanuel College with a B.S. in Neuroscience and was 
              engaged in undergraduate neuroscience research at both Emmanuel and the F.M. 
              Kirby Neurobiology Center at Boston Children’s Hospital. After graduation, he 
              worked at the Whitehead Institute for Biomedical Research for three years helping 
              with research in molecular biology and genetics as a part of the lab of MIT professor 
              Rudolf Jaensich. As a software engineer, Nick has worked on several projects from 
              development to production involving full-stack web development, designing and working 
              with cloud architecture, and building CI/CD pipelines. He has a burgeoning interest in 
              machine learning and how it can be used to help solve research problems within the fields 
              of molecular biology and genomics.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={guzman.childImageSharp.fluid}
                alt="Guzman"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Guzman Vigliecca</Header>
              <Header as="h5">Software Engineering</Header>
              <p>
              Guzman obtained a Master of Science in Computer Science degree from Northeastern
               University and has a bachelor's degree in Electronic Engineering from Universidad 
               de la Republica (Uruguay). In 2018, he was granted the Fulbright Scholarship and 
               came to the United States to study his Master's degree. After having graduated in 
               2021, he joined Lattice Automation as a Software Engineer. Guzman has experience 
               in full-stack and mobile development, and has also been involved in machine learning 
               and algorithms projects. He is also interested in math, being part of the Mathematics 
               Department of Universidad de la Republica from 2015 to 2018. 
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={isaac.childImageSharp.fluid}
                alt="Isaac"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Isaac Guerreiro</Header>
              <Header as="h5">Software Engineering</Header>
              <p>
                Isaac is a synthetic biologist and software engineer. He co-founded the first
                synthetic biology and advanced biology laboratory in the Amazon rainforest.
                Isaac has been a member and advisor for various iGEM teams, including the 2021 
                global iGEM team called Open Science Network, which won a gold medal. In addition 
                to his expertise in biology, Isaac is a full-stack developer and an open-source 
                contributor in the lab automation and synthetic biology software ecosystem. He is 
                also a member of the iGEM Engineering Committee, leading to a publication in 2023 as 
                a result of this collaboration.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={nishant.childImageSharp.fluid}
                alt="Nishant"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Nishant Jha</Header>
              <Header as="h5">Software Engineering</Header>
              <p>
                Nishant is a senior software engineer with a deep interest in biology. He holds a Masters
                degree in Computer Science from the University of Virginia. Alongside his CS education,
                he spent time at the bench in the Parsons-Bauer lab studying pancreatic cancer. Prior to
                joining Lattice, he worked at the Broad Institute building data pipelines and web apps for
                cancer researchers. During this time he was published in Nature Genetics for a pediatric
                cancer visualization. At Lattice he works across the web development stack on databases,
                backend APIs, and frontend UIs.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        {/* <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={ernst.childImageSharp.fluid}
                alt="Ernst Oberortner"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Ernst Oberortner, Ph.D.</Header>
              <Header as="h5">Technical Counsel | Software Engineering</Header>
              <p>
                Ernst is a Data Scientist and Software Engineer at the U.S. DOE
                Joint Genome Institute (JGI) of Lawrence Berkeley National
                Laboratory. He leads the Syntetic Biology Informatics (iSynBio)
                team, whose work includes the development of software solutions
                for the design and build of synthetic DNA, such as optimization
                for expression, biosecurity screening, and construct QA/QC using
                NGS. In addition, the iSynBio team works on data-driven
                solutions for a more predictable biomanufacturing process and
                functional behavior. Prior to joining DOE JGI, Ernst completed a
                postdoctoral fellowship at Boston University in the Densmore
                lab, working on CAD tools such as Eugene and Clotho. Ernst is a
                strong advocate of standards in the Synthetic Biology community.
                He is a member of the SBOL developers community and steering
                committee. Ernst earned a Ph.D. and M.Sc. Computer Science from
                University of Technology Vienna, Austria.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        {/* <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={luis.childImageSharp.fluid}
                alt="Luis Ortiz "
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Luis Ortiz, Ph.D.</Header>
              <Header as="h5">Technical Counsel | Synthetic Biology</Header>
              <p>
                Luis earned a Ph.D. in Molecular Biology in Doug Densmore's
                Cross-Disciplinary Integration of Design Automation Research
                (CIDAR) Lab at Boston University. His thesis research focuses on
                automation of Synthetic Biology processes, with an emphasis on
                DNA assembly automation via software and liquid-handling robots,
                as well as the development of high-throughput screens for novel
                enzyme discovery. This work has generated a provisional patent
                in addition to several publications in academic journals
                including SLAS Technology, Lab on a Chip, and the Journal of
                Visual Experiments. Prior to joining the CIDAR Lab Luis held
                several positions at the University of Florida and Harvard
                Medical School, and has over 11 years of research experience.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={jenny.childImageSharp.fluid}
                alt="Jenny Rooke"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Jenny Rooke, Ph.D.</Header>
              <Header as="h5">Business Advisor</Header>
              <p>
                Jenny Rooke is Managing Director at Genoa Ventures, where she
                invests in early stage life sciences companies, specializing in
                research tools, diagnostics, and synthetic biology. Jenny’s
                investing experience includes roles at F-Prime Capital, where
                she served her Kauffman Fellowship, Anterra Capital, and the
                Bill & Melinda Gates Foundation. Prior to investing, Jenny held
                multiple senior operating roles at U.S. Genomics, a
                venture-backed biotech start-up advancing single molecule
                detection technologies, and consulted to pharma and biotech
                companies with McKinsey & Co. Dr. Rooke holds a PhD in genetics
                from Yale University and a BS in Physics from Georgia Tech.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={calvin.childImageSharp.fluid}
                alt="Calvin Schmidt"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Calvin Schmidt, Ph.D.</Header>
              <Header as="h5">Technical Counsel | Machine Learning</Header>
              <p>
                Calvin completed his Ph.D. in the laboratory of Professor
                Christina Smolke in the Department of Bioengineering at Stanford
                University. His research involved the use of machine learning to
                automate the design of biological systems, specifically in the
                prediction of biological phenotype from genotype. He holds a
                B.S. in Biochemistry from Rice University, an M.S. in
                Bioengineering from Stanford University, and a Ph.D. in
                Bioengineering from Stanford University with multiple
                publications in the field of synthetic biology.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={amoolya.childImageSharp.fluid}
                alt="Amoolya Singh"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Amoolya Singh, Ph.D.</Header>
              <Header as="h5">Scientific Advisor</Header>
              <p>
                Amoolya is Head of Computing Calico Life Sciences. Prior to
                joining Calico, Amoolya was Vice President at Amyris where she
                led Amyris R&D's Scientific Computing group, whose work includes
                innovations in genotype representation and data visualization;
                metabolic modeling and design-of-experiments to perturb
                microbial biochemical pathways; and statistical process control
                to accelerate Amyris' design-build-test-learn cycle. Amoolya
                earned a Ph.D. in computational biology from UC Berkeley. Prior
                to joining Amyris, Amoolya completed a postdoctoral fellowship
                at the European Molecular Biology Lab in Heidelberg, Germany
                (with Peer Bork) and a Computational & Life Sciences fellowship
                at Emory University (with Bruce Levin) in the fields of
                comparative genomics and metagenomics, population genetics, and
                experimental evolution.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={josh.childImageSharp.fluid}
                alt="Joshua Timmons"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Joshua Timmons</Header>
              <Header as="h5">Technical Counsel | Software Engineering</Header>
              <p>
                Josh graduated with a degree in biology and has years of
                molecular biology and synthetic biology experience from time in
                Dr. Slava Epstein’s lab at Northeastern, Dr. Eric Wong’s lab at
                Beth Israel Deaconess Medical Center and Harvard Medical School,
                and the Northeastern iGEM teams he founded/captained. As a
                full-stack software engineer, he has led multiple engagements
                building large platforms with novel DNA assembly methods,
                algorithms, and visualizations. His industry experience covers
                web applications, command line applications, scientific
                libraries, servers, APIs, and databases. In his research with
                Dr. Densmore, Dr. Wong, and Dr. Epstein, he has published nine
                peer-review articles covering biodesign, molecular simulation,
                image processing, and medical research.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Img
                className="headshot"
                fluid={bryan.childImageSharp.fluid}
                alt="Bryan Der"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} verticalAlign="middle">
              <Header as="h2">Bryan Der, Ph.D.</Header>
              <Header as="h5">Technical Counsel | Machine Learning</Header>
              <p>
                Bryan Der earned a Ph.D. in the Kulhman lab at UNC working in
                computational design and experimental characterization of
                protein-protein interactions. He then completed a postdoctoral
                fellowship in the Voigt lab at MIT and Densmore lab at Boston
                University developing algorithms for automating design of
                repressor-based genetic logic circuits. His academic research
                resulted in over 20 publications. He worked at Lattice
                Automation for one year as a full-stack software engineer in
                biodesign automation. Since that time, he has worked as a
                Machine Learning Software Engineer in the financial industry,
                gaining expertise in supervised learning and model
                explainability, as well cloud computing and big data
                technologies.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        {/* <Divider className="computer large screen widescreen only" /> */}
        <Divider hidden />
        <Divider hidden />

        {/* <Img
          className="computer large screen widescreen only"
          fluid={team.childImageSharp.fluid}
          alt="Lattice team photo"
        /> */}
      </Container>
    </Segment>
  );
};

// from https://www.gatsbyjs.org/docs/working-with-images/
export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

// from https://www.gatsbyjs.org/docs/working-with-images/
export const teamImg = graphql`
  fragment teamImg on File {
    childImageSharp {
      fluid(maxWidth: 1127, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    amoolya: file(relativePath: { eq: "img/heads/amoolya.png" }) {
      ...squareImage
    }

    doug: file(relativePath: { eq: "img/heads/doug.jpg" }) {
      ...squareImage
    }

    jenny: file(relativePath: { eq: "img/heads/jenny.jpg" }) {
      ...squareImage
    }

    josh: file(relativePath: { eq: "img/heads/josh.jpg" }) {
      ...squareImage
    }

    kevin: file(relativePath: { eq: "img/heads/kevin.jpg" }) {
      ...squareImage
    }

    bryan: file(relativePath: { eq: "img/heads/bryander.jpg" }) {
      ...squareImage
    }

    ernst: file(relativePath: { eq: "img/heads/ernst.jpg" }) {
      ...squareImage
    }

    calvin: file(relativePath: { eq: "img/heads/calvinSchmidt.jpg" }) {
      ...squareImage
    }

    luis: file(relativePath: { eq: "img/heads/luisOrtiz.jpg" }) {
      ...squareImage
    }

    nick: file(relativePath: { eq: "img/heads/nick.jpg" }) {
      ...squareImage
    }

    guzman: file(relativePath: { eq: "img/heads/guzman.jpg" }) {
      ...squareImage
    }

    nishant: file(relativePath: { eq: "img/heads/nishant.jpg" }) {
      ...squareImage
    }

    isaac: file(relativePath: { eq: "img/heads/isaac.png" }) {
      ...squareImage
    }

    team: file(relativePath: { eq: "img/lattice-team-2018.jpg" }) {
      ...teamImg
    }
  }
`;

export default withLayout(TeamPage);
